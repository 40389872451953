$(document).ready(function () {
  FilePond.registerPlugin(FilePondPluginImagePreview);

  var options = {
    server: {
      process: (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options
      ) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        const formData = new FormData();
        formData.append('filepond', file, file.name);

        const request = new XMLHttpRequest();
        $('.sbt_approval').prop('disabled', true);
        request.open('POST', '/save_file');

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(request.responseText);
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'project[image]';
            input.value = request.responseText;
            $('.file_upload_div').html(input);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
          }
          $('.sbt_approval').prop('disabled', false);
        };

        request.send(formData);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      },
    },
  };
  const inputElement = document.querySelector('.filepond');
  const pond = FilePond.create(inputElement, options);
});
