// app/assets/javascripts/modals.js

$(document).ready(function () {
  const modal_holder_selector = '#modal-holder';
  const modal_selector = '.modal';

  $(document).on('click', 'a[data-modal]', function () {
    const location = $(this).attr('href');
    // Load modal dialog from server
    $.get(location, (data) => {
      $(modal_holder_selector).html(data).find(modal_selector).modal();
      $('.select2').select2({
        theme: 'bootstrap4',
      });
      // datepicker
      flatpickr('.datepicker', {
        altInput: true,
        dateFormat: 'm/d/Y',
      });
      flatpickr('.datetime_picker', {
        altInput: true,
        enableTime: true,
        allowInput: false,
        dateFormat: 'm/d/Y H:i',
      });

      flatpickr('.time_picker', {
        enableTime: true,
        noCalendar: true,
        altInput: true,
        dateFormat: 'm/d/Y H:i',
      });
      // dynamic dropdowns
    });

    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      //$('.modal-backdrop').remove();
      // Update modal content
      console.log(data);
      // const modal = $(data).find('body').html();
      // $(modal_holder_selector).html(modal).find(modal_selector).modal();
      console.log('ereee');
      $('.resp').html('');
      $('.resp').html(data.resp);
      $('.simple_form').trigger('reset');
      $('.select2').trigger('change');
      $('.select2').val('').trigger('change');
    }

    return false;
  });
  $(document).on('hidden.bs.modal', '#mainModal', function (e) {
    $('.modal-backdrop').remove();
  });
});
