import 'jquery-ui/ui/widgets/sortable';
$(document).ready(function () {
  $('#products-list').sortable({
    update: function (event, ui) {
      var pos = ui.item.index();
      var url = ui.item.data('sort-url');
      console.log(url, ui);
      $.ajax({
        type: 'GET',
        url: url,
        data: { pos: pos },
        success: function (data, textStatus, jqXHR) {
          //window.location = '';
        },
      });
    },
  });
});
