const { data } = require('jquery');

$(document).ready(function () {
  var current_fs, next_fs, previous_fs; //fieldsets
  var opacity;
  var current = 1;
  var steps = $('fieldset').length;

  setProgressBar(current);

  var project_form = $('#msform').validate({ ignore: ':not(:visible)' });

  $('.next').click(function () {
    flatpickr('.datepicker', {
      altInput: true,
      dateFormat: 'm/d/Y',
    });
    $('html, body').animate(
      {
        scrollTop: $('.project_form').offset().top,
      },
      0
    );
    current_fs = $(this).parent().parent();
    next_fs = $(this).parent().parent().next();
    if (!$('#msform').valid()) {
      return false;
    }
    if ($(this).data('type') == 'topic') {
      var reach = $('#project_reach').val();
      var target = $('#project_target').val();
      var resources_to_share = $('#project_resources_to_share').val();
      var focus = $('#project_focus').val();
      $.get('/find_products', {
        reach: reach,
        target: target,
        resources_to_share: resources_to_share,
        focus: focus,
      }).done(function (data) {
        $('.product_item').each(function (i, obj) {
          if (!data.includes($(obj).data('id'))) {
            $(obj).addClass('d-none');
          } else {
            $(obj).removeClass('d-none');
          }
        });
        console.log('Data Loaded: ' + data);
      });
    }
    if ($(this).data('type') == 'products') {
    }
    if ($(this).data('type') == 'project') {
      $('#confirm').addClass('active');
      var valuesToSubmit = $('#msform').serialize();
      $.ajax({
        type: 'POST',
        url: $('#msform').attr('action'), //sumbits it to the given url of the form
        data: valuesToSubmit,
        dataType: 'JSON', // you want a difference between normal and ajax-calls, and json is standard
      }).done(function (data) {
        console.log(data);
        if (data.status == 'error') {
          console.log(data.error);
          $(window).scrollTop(0);
          $('.form-errors').html(data.error).addClass('alert alert-danger');
          return false;
        } else {
          console.log('go');
        }
      });
    }

    //Add Class Active
    $('#progressbar li').eq($('fieldset').index(next_fs)).addClass('active');

    //show the next fieldset
    next_fs.show();
    //hide the current fieldset with style
    current_fs.animate(
      { opacity: 0 },
      {
        step: function (now) {
          // for making fielset appear animation
          opacity = 1 - now;

          current_fs.css({
            display: 'none',
            position: 'relative',
          });
          next_fs.css({ opacity: opacity });
        },
        duration: 500,
      }
    );
    setProgressBar(++current);
  });

  $('.previous').click(function () {
    current_fs = $(this).parent().parent();
    previous_fs = $(this).parent().parent().prev();

    //Remove class active
    $('#progressbar li')
      .eq($('fieldset').index(current_fs))
      .removeClass('active');

    //show the previous fieldset
    previous_fs.show();

    //hide the current fieldset with style
    current_fs.animate(
      { opacity: 0 },
      {
        step: function (now) {
          // for making fielset appear animation
          opacity = 1 - now;

          current_fs.css({
            display: 'none',
            position: 'relative',
          });
          previous_fs.css({ opacity: opacity });
        },
        duration: 500,
      }
    );
    setProgressBar(--current);
  });

  function setProgressBar(curStep) {
    var percent = parseFloat(100 / steps) * curStep;
    percent = percent.toFixed();
    $('.progress-bar').css('width', percent + '%');
  }

  $('.submit').click(function () {
    return false;
  });
});
